import { computed, Ref } from 'vue';
import {
  PretrialActiveFormScheme,
  PretrialAutomationModel,
} from '@/components/automation/pretrial/tabs/types';
import { getCommonFields } from '@/components/automation/pretrial/tabs/fields/common';
import { getCommonPFields } from '@/components/automation/pretrial/tabs/fields/commonP';
import { getTextFields } from '@/components/automation/pretrial/tabs/fields/text';
import { getClaimFields } from '@/components/automation/pretrial/tabs/fields/claim';
import { ActiveFormFieldType } from '@/hooks/useActiveForm';
import { useLocalI18n } from '@/hooks/useLocalI18n';
import { useI18n } from 'vue-i18n';
import { getVoiceFields } from '@/components/automation/pretrial/tabs/fields/voice';
import { useFormEmployees } from '@/components/automation/useEmployees';
import { useAsyncState } from '@vueuse/core';
import { useCustomLocales } from '@/components/dialog/dialogs/debtor/useCustomLocales';

export const useFields = (model: Ref<PretrialAutomationModel>) => {
  const { t } = useLocalI18n('debtor.automatizing');
  const { t: rootT } = useI18n();
  const { onQueryEmployee, employees } = useFormEmployees();
  const { fetchVoiceStatuses } = useCustomLocales();

  const { state } = useAsyncState<{
    value: string;
    label: string;
  }[]>(async () => {
    const options = await fetchVoiceStatuses();

    return options;
  }, []);

  const statusOptions = computed<Array<{label: string; value: string}>>(() => state?.value || []);

  return {
    fields: computed(() => (([
      ...getCommonFields(model),
      ...getTextFields(model, rootT),
      ...getVoiceFields(model, rootT, statusOptions, onQueryEmployee, employees),
      ...getCommonPFields(model, rootT),
      ...getClaimFields(model, rootT),
    ]) as PretrialActiveFormScheme)
      .filter(Boolean)
      .map((field) => {
        if (field.type === 'label') {
          return {
            key: field.key,
            type: field.type,
            label: field.label ?? t(`label.${String(field.key)}`),
            style: field.style,
            ...((field as any).ionFieldGroup ? { ionFieldGroup: (field as any).ionFieldGroup } : {}),
          };
        }

        return ({
          ...field,
          field: field.field || field.key,
          label: field.label ?? t(`field.${String(field.key)}`),
          type: field.type || ActiveFormFieldType.input,
          options: {
            ...(field.options || {}),
            [
            field.type === ActiveFormFieldType.checkbox
              ? 'label'
              : 'placeholder'
            ]: field.options?.[
              field.type === ActiveFormFieldType.checkbox
                ? 'label'
                : 'placeholder'
            ] ?? t(`field.${String(field.key)}`),
            id: `modal_automation_judicial_${field.type}_${field.key}`,
          },
        });
      })),
  };
};
