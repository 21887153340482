import { CompanySettings, DebtNotificationKey, Nullable } from '@/hooks/useCompanies';
import { PeriodicTaskSchedule } from '@/hooks/useSchedule';
import { PretrialClaimSettings } from '@/hooks/usePretrialClaimSettings';
import { ActiveFormField } from '@/hooks/useActiveForm';

export type CompanySettingsPretrial = CompanySettings
  & {
  debt_notifications_period: null | PeriodicTaskSchedule;
  pretrial_claim_period: null | PeriodicTaskSchedule;
  pretrial_claim_pochta_ru: boolean;
  pretrial_claim_is_ordered: boolean;
  pretrial_claim_email: boolean;
}

export type PretrialClaimSendType = 'pochta' | 'ordered' | 'email' | null

export type PeriodicTimeSchdeule = {
  hour: string;
  minute: string;
}

export type PretrialAutomationModel = {
  auto_create_sms_notifications: boolean;
  auto_create_voice_notifications: boolean;
  auto_create_email_notifications: boolean;
  voice_notifications_by_period: boolean;
  voice_timezone_by_address: boolean;
  voice_weekday_period_from: PeriodicTimeSchdeule | null;
  voice_weekday_period_to: PeriodicTimeSchdeule | null;
  voice_weekend_or_holiday_period_from: PeriodicTimeSchdeule | null;
  voice_weekend_or_holiday_period_to: PeriodicTimeSchdeule | null;
  voice_auto_exclude_numbers: boolean;
  voice_excluding_all_numbers_statuses: string[];
  voice_excluding_current_numbers_statuses: string[];
  voice_assigned_user: number;
  debt_notifications_period_voice: PeriodicTaskSchedule | null;
  debt_notifications_period_sms: PeriodicTaskSchedule | null;
  debt_notifications_period_email: PeriodicTaskSchedule | null;
  auto_court_transfer: boolean;
  actual_debt: boolean;
  debt_notifications_enabled: Nullable<boolean>;
  debt_notification_threshold: number;
  debt_notification_failed_attempts: number;
  court_days_overdue_enabled: boolean;
  court_days_overdue: null | number;
  court_threshold_enabled: boolean;
  court_threshold: number | null;
  voice_send_sms_if_unavailable: boolean;
  dialing_redial_interval: number;
  max_dialing_attempts: number;
  pretrial_claim_enabled: boolean;
  pretrial_claim_period: PeriodicTaskSchedule | null;
  pretrial_claim_threshold: number;
  pretrial_claim_send_type: PretrialClaimSendType;
  pretrial_claims: PretrialClaimSettings[];
  request_recall_dialing_redial_interval: number;
}

export type LabelFormItem = { type: 'label'; label: string; key: string; style: Record<any, any> }

export type DraggableFormItem = {
  type: 'draggable'; label: string; field: string; key: string; style: Record<any, any>; options: Record<any, any>;
}

export type PretrialActiveFormScheme = (
  ActiveFormField<PretrialAutomationModel>
  | LabelFormItem
  | DraggableFormItem
  )[];

export enum AutoTabKey {
  commonP = 'commonP',
  text = 'text',
  voice = 'voice',
  claim = 'claim',
}

export type OnQueryEmployee = ({ query }: { query: string }) => Promise<void>;
