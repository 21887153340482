import { fieldsUniter } from '@/components/automation/pretrial/tabs/utils';
import { AutoTabKey, PretrialAutomationModel, OnQueryEmployee } from '@/components/automation/pretrial/tabs/types';
import { ActiveFormFieldType } from '@/hooks/useActiveForm';
import { ITextInputType } from '@/components/textInput/useTextInput';
import { Ref } from 'vue';
import { Employee } from '@/hooks/useEmployees';

export const getVoiceFields = (
  model: Ref<PretrialAutomationModel>,
  rootT: (s: string) => string,
  options: Ref<Array<{label: string; value: string}>>,
  onQueryEmployee: OnQueryEmployee,
  employees: Ref<Employee[]>,
) => {
  return fieldsUniter(AutoTabKey.voice, [
    {
      key: 'auto_create_voice_notifications',
      field: 'auto_create_voice_notifications',
      label: '',
      type: ActiveFormFieldType.checkbox,
      state: ['primary'],
      options: {
        placeholder: null,
        allowEmpty: false,
        state: ['primary', 'horizontal'],
        options: [
          { value: true, label: rootT('other.yes') },
          { value: false, label: rootT('other.no') },
        ],
      },
    },
    {
      key: 'voice_timezone_by_address',
      field: 'voice_timezone_by_address',
      label: '',
      type: ActiveFormFieldType.checkbox,
      state: ['primary'],
      options: {
        isDisabled: !model.value.auto_create_voice_notifications,
        placeholder: null,
        allowEmpty: false,
        state: ['primary', 'horizontal'],
        options: [
          { value: true, label: rootT('other.yes') },
          { value: false, label: rootT('other.no') },
        ],
      },
    },
    {
      key: 'debt_notifications_period_voice',
      type: ActiveFormFieldType.schedule,
      state: ['primary'],
      options: {
        state: ['primary'],
        isDisabled: !model.value.auto_create_voice_notifications,
      },
    },
    {
      key: 'week_days_label',
      type: 'label',
      options: {
      },
    },
    {
      key: 'weekend_days_label',
      type: 'label',
      options: {
      },
    },
    {
      key: 'voice_notifications_by_period',
      field: 'voice_notifications_by_period',
      label: '',
      type: ActiveFormFieldType.checkbox,
      state: ['primary'],
      options: {
        isDisabled: !model.value.auto_create_voice_notifications,
        placeholder: null,
        allowEmpty: false,
        state: ['primary', 'horizontal'],
        options: [
          { value: true, label: rootT('other.yes') },
          { value: false, label: rootT('other.no') },
        ],
      },
    },
    {
      key: 'voice_weekday_period_from',
      field: 'voice_weekday_period_from',
      type: ActiveFormFieldType.schedule,
      options: {
        state: ['primary', 'horizontal'],
        isDisabled: !model.value.auto_create_voice_notifications || !model.value.voice_notifications_by_period,
        availableTabs: [],
        justTime: true,
      },
    },
    {
      key: 'voice_weekday_period_to',
      field: 'voice_weekday_period_to',
      type: ActiveFormFieldType.schedule,
      options: {
        state: ['primary', 'horizontal'],
        isDisabled: !model.value.auto_create_voice_notifications || !model.value.voice_notifications_by_period,
        availableTabs: [],
        justTime: true,
      },
    },
    {
      key: 'voice_weekend_or_holiday_period_from',
      field: 'voice_weekend_or_holiday_period_from',
      type: ActiveFormFieldType.schedule,
      options: {
        state: ['primary', 'horizontal'],
        isDisabled: !model.value.auto_create_voice_notifications || !model.value.voice_notifications_by_period,
        availableTabs: [],
        justTime: true,
      },
    },
    {
      key: 'voice_weekend_or_holiday_period_to',
      field: 'voice_weekend_or_holiday_period_to',
      type: ActiveFormFieldType.schedule,
      options: {
        state: ['primary', 'horizontal'],
        isDisabled: !model.value.auto_create_voice_notifications || !model.value.voice_notifications_by_period,
        availableTabs: [],
        justTime: true,
      },
    },
    {
      key: 'dialing_redial_interval',
      field: 'dialing_redial_interval',
      type: ActiveFormFieldType.input,
      options: {
        isDisabled: !model.value.auto_create_voice_notifications,
        type: ITextInputType.number,
      },
    },
    {
      key: 'max_dialing_attempts',
      field: 'max_dialing_attempts',
      type: ActiveFormFieldType.input,
      options: {
        isDisabled: !model.value.auto_create_voice_notifications,
        type: ITextInputType.number,
      },
    },
    {
      key: 'request_recall_dialing_redial_interval',
      field: 'request_recall_dialing_redial_interval',
      type: ActiveFormFieldType.input,
      options: {
        isDisabled: !model.value.auto_create_voice_notifications,
        type: ITextInputType.number,
      },
    },
    {
      key: 'voice_auto_exclude_numbers',
      field: 'voice_auto_exclude_numbers',
      type: ActiveFormFieldType.checkbox,
      label: '',
      state: ['primary'],
      options: {
        isDisabled: !model.value.auto_create_voice_notifications,
        placeholder: null,
        allowEmpty: false,
        state: ['primary', 'horizontal'],
        options: [
          { value: true, label: rootT('other.yes') },
          { value: false, label: rootT('other.no') },
        ],
      },
    },
    {
      key: 'voice_excluding_all_numbers_statuses',
      field: 'voice_excluding_all_numbers_statuses',
      type: ActiveFormFieldType.select,
      options: {
        isDisabled: !model.value.auto_create_voice_notifications || !model.value.voice_auto_exclude_numbers,
        options: options.value,
      },
    },
    {
      key: 'voice_excluding_current_numbers_statuses',
      field: 'voice_excluding_current_numbers_statuses',
      type: ActiveFormFieldType.select,
      options: {
        isDisabled: !model.value.auto_create_voice_notifications || !model.value.voice_auto_exclude_numbers,
        options: options.value,
      },
    },
    {
      key: 'voice_assigned_user',
      field: 'voice_assigned_user',
      type: ActiveFormFieldType.select,
      options: {
        multiple: false,
        displayField: ['last_name', 'first_name'],
        valueField: 'id',
        options: employees.value,
        isDisabled: !model.value.auto_create_voice_notifications,
      },
      defaultValue: [],
      onQuery: onQueryEmployee,
    },
    {
      key: 'actual_debt',
      field: 'actual_debt',
      label: '',
      options: {
        isDisabled: !model.value.auto_create_voice_notifications,
      },
      type: ActiveFormFieldType.checkbox,
    },
  ]);
};
