import { useLocalI18n } from '@/hooks/useLocalI18n';
import { CompanySettings, useCompanies } from '@/hooks/useCompanies';
import { useFormEmployees } from '@/components/automation/useEmployees';
import { useCompanySettings } from '@/components/automation/useCompanySettings';
import { useProtectedDefaultCompany } from '@/hooks/useProtectedDefaultCompany';
import { useJudicialModel } from '@/components/automation/judicial/useJudicialModel';
import { CompanyPeriodicTask, PeriodicTaskSchedule, useSchedule } from '@/hooks/useSchedule';
import { IToastLevel, useToast } from '@/hooks/useToast';
import { useCompanyAttachments } from '@/hooks/useCompanyAttachments';
import {
  computed, ref, SetupContext, watch, watchEffect,
} from 'vue';
import { ProductionType } from '@/hooks/useConstructor';
import { Employee } from '@/hooks/useEmployees';
import { usePreventLeaveTrigger } from '@/hooks/usePreventLeave';
import { arrayFrom } from '@/utils/object';
import {
  CompanySettingsJudicial,
  PeriodicTaskDescription,
} from '@/components/automation/judicial/types';
import { periodicTasks } from '@/components/automation/judicial/periodicTasks';
import { useDebtors } from '@/hooks/useDebtors';
import { useTabs } from '@/components/automation/judicial/tabs/tabs';
import { useCustomLocales } from '@/components/dialog/dialogs/debtor/useCustomLocales';
import { useAsyncState } from '@vueuse/core';

export const useAutomationJudicial = (emit: SetupContext['emit'] | any) => {
  const leaveController = usePreventLeaveTrigger();
  const { t } = useLocalI18n('debtor.automatizing');
  const isLoading = ref(true);
  const {
    updateCompanySettings,
  } = useCompanies();
  const { employees } = useFormEmployees();
  const { companySettings } = useCompanySettings();
  const { companyModuleType, companyId } = useProtectedDefaultCompany();
  const {
    model, onUpdateModelValue, updateModel, getPreparedModel, emailOptions,
  } = useJudicialModel(employees);

  const scheduleApi = useSchedule<CompanyPeriodicTask>();
  const { showToast } = useToast();
  const { fetchDebtors } = useDebtors();

  const printType = ref<string>('print');

  const {
    locales, fetchLocales,
  } = useCustomLocales();

  const { state, isLoading: isPrintTypesLoading } = useAsyncState<{
    value: string;
    label: string;
    is_subgroup: boolean;
  }[]>(async () => {
    await fetchLocales();
    const judicialPrintTypes = Object.entries(locales.value.ru['debtor.courts.judicial_type'])
      .map(([value, label]) => ({ label, value }));
    const courtOrderPrintTypes = Object.entries(locales.value.ru['debtor.courts.court_order_type'])
      .map(([value, label]) => ({ label, value }));

    // @ts-ignore
    const options: { value: string; label: string; is_subgroup: boolean }[] = judicialPrintTypes.toSpliced(judicialPrintTypes.findIndex(({ value }) => value === 'court_order'), 1)
      .map((t:{value: string; label: string}) => ({ ...t, is_subgroup: false }))
      .concat(courtOrderPrintTypes
        .map((t) => ({ ...t, is_subgroup: true })));

    if (model.value.show_automatic_attachments) {
      printType.value = options[0].value;
    }

    return options;
  }, []);

  const printTypeOptions = computed(() => state?.value || []);

  const {
    fetchDocuments,
    documents: documentsModel,
    isUploading: documentIsUploading,
    updateDocuments,
    isLoading: documentsIsLoading,
  } = useCompanyAttachments(
    companyId,
    computed(() => ProductionType.judicial),
    computed(() => (printTypeOptions.value?.find(({ value }) => value === printType.value)?.is_subgroup ? 'court_order' : printType.value) as string),
    computed(() => {
      const item = printTypeOptions.value?.find(({ value }) => value === printType.value);

      return item?.is_subgroup ? item.value : '';
    }),
  );

  watchEffect(() => {
    if (!model.value.show_automatic_attachments) {
      printType.value = 'print';
    }
  });

  watch(computed(() => printType.value), async (p) => {
    if (p) {
      await fetchDocuments();
    }
  });

  scheduleApi.availableTasksGetList().then(
    (r) => {
      console.log('availableTasksGetList', r);
    },
  );

  const fetchData = async (
    [companySettings, employees]: [CompanySettings | null, Employee[] | null],
  ) => {
    if (companySettings && employees) {
      isLoading.value = true;
      const settingsCopied = JSON.parse(
        JSON.stringify(companySettings),
      ) as CompanySettingsJudicial;

      const response = await scheduleApi.task.getList();
      if (!response.status) {
        isLoading.value = false;
        return;
      }
      const responseTasks = response.response.map(wtfMapping);
      periodicTasks.forEach(prepareTask);
      settingsCopied.debtors_amount = (await fetchDebtors({
        filters: {
          debtor_debt_min: settingsCopied.judicial_amount_from,
          debtor_debt_max: settingsCopied.judicial_amount_to,
          production_type: ProductionType.judicial,
          company_id: companyId.value,
          module: companyModuleType.value,
        },
        page: 1,
        limit: 1,
      }))?.response?.count || 0;
      updateModel(settingsCopied, employees);
      isLoading.value = false;

      // eslint-disable-next-line no-inner-declarations
      function prepareTask(taskDescription: PeriodicTaskDescription) {
        const { taskName, taskField, taskFlagField } = taskDescription;

        if (taskName === 'judicial.auto_rosreestr_discharge'
          || taskName === 'judicial.auto_rosreestr_discharge_force') {

          const tasks = responseTasks.filter(
            (task) => task.name === taskName,
          );
          if (tasks.length) {
            if (tasks.length === 1) {
              settingsCopied[taskFlagField] = true;
              settingsCopied[taskField] = tasks[0].schedule;
            } else {
              settingsCopied[taskFlagField] = true;
              settingsCopied[taskField] = tasks.map((task) => task.schedule);
            }
          }
        } else {
          const taskPeriod = responseTasks.find(
            (task) => task.name === taskName,
          );
          if (taskPeriod) {
            settingsCopied[taskFlagField] = true;
            settingsCopied[taskField] = taskPeriod.schedule;
          }
        }
      }
    }
  };

  watch([companySettings, employees], ([c, e]) => {
    return fetchData([c, e]);
  });

  const errorsMap = ref<Record<string, string[]>>({});

  const submit = async () => {
    if (isLoading.value) {
      return;
    }
    errorsMap.value = {};
    if (model.value.auto_rosreestr_discharge || model.value.auto_rosreestr_discharge_period_force) {
      if (!model.value.rosreestr_characteristics && !model.value.rosreestr_movement && !model.value.unknown_owner_rosreestr_movement) {
        errorsMap.value = {
          rosreestr_movement: ['Необходимо активировать одну из опций выше'],
        };
        return;
      }
    }
    isLoading.value = true;
    const preparedModel = getPreparedModel();
    const { status, response } = await updateCompanySettings({
      id: companyId.value,
      payload: preparedModel as unknown as Partial<CompanySettings>,
    });
    if (!status) {
      onSaveError();
      return;
    }
    const tasksResponse = await scheduleApi.task.getList();
    if (!tasksResponse.status) {
      onSaveError();
      return;
    }
    function onSaveError() {
      showToast({
        label: 'Ошибка сохранения, попробуйте еще раз',
        level: IToastLevel.danger,
      });
      isLoading.value = false;
    }

    console.log('tasks response', tasksResponse.response);

    tasksResponse.response = tasksResponse.response.map(wtfMapping);

    console.log('after mapping tasksResponse', tasksResponse.response);

    let anyError = false;

    const processTask = async (task: PeriodicTaskDescription) => {

      const { taskField, taskName } = task;
      const taskSchedule = preparedModel[taskField] as PeriodicTaskSchedule|PeriodicTaskSchedule[];

      const tasksToDelete = tasksResponse.response.filter(
        (task) => task.name === taskName,
      );
      if (tasksToDelete.length) {
        await Promise.all(
          tasksToDelete.map((task) => scheduleApi.task.delete(task.id)),
        );
      }

      if (taskSchedule) {
        return Promise.all(
          arrayFrom(taskSchedule).map((task, i) => scheduleApi.task.create({
            name: taskName,
            schedule: task as PeriodicTaskSchedule,
            // некоторые таски должны не только добавляться в крон, но и при сохранении запускаться
            // за это отвечает ключ run_immediately
            // т.к. логика scheduleSelector позволяет создавать несколько тасок
            // то его нужно добавлять только в первую из тасок
            ...(
              ['judicial.auto_rosreestr_discharge', 'judicial.auto_rosreestr_discharge_force'].includes(taskName)
                && i === 0
                ? { run_immediately: false }
                : {}
            ),
            ...(taskName === 'judicial.auto_rosreestr_discharge' ? {
              name: 'judicial.auto_rosreestr_discharge_force',
              extra: { force: false },
            } : {}),
            ...(taskName === 'judicial.auto_rosreestr_discharge_force' ? { extra: { force: true } } : {}),
          }).then((response) => {
            if (!response.status) {
              anyError = true;
              showToast({
                label: 'pureLabel',
                message: 'pure',
                level: IToastLevel.danger,
                params: {
                  label: 'Ошибка автоматизации',
                  message: response.response[0],
                },
              });
            }
          })),
        );
      }
    };

    await Promise.all(periodicTasks.map(processTask));

    if (!status) {
      await showToast({
        label: 'pureLabel',
        message: 'pure',
        params: {
          label: 'Ошибка автоматизации',
          message: (response as any).detail,
        },
      });
    }

    leaveController.reset();

    if (!status || anyError) {
      await fetchData([companySettings.value as CompanySettings, employees.value]);
    } else {
      emit('close');
    }

    isLoading.value = false;
  };

  function wtfMapping(task: CompanyPeriodicTask): CompanyPeriodicTask {
    if (task.name !== 'judicial.auto_rosreestr_discharge_force') {
      return task;
    }
    if (task.extra?.force) {
      return {
        ...task,
        name: 'judicial.auto_rosreestr_discharge_force',
      };
    }
    return {
      ...task,
      name: 'judicial.auto_rosreestr_discharge',
    };
  }

  const reset = () => leaveController.reset();

  return {
    t,
    model,
    ...useTabs(model, emailOptions),
    printTypeOptions,
    printType,
    submit,
    reset,
    onUpdateModelValue,
    updateDocuments,

    companyModuleType,
    documentsModel,
    documentIsUploading,
    documentsIsLoading,
    isLoading,
    isPrintTypesLoading,
    errorsMap,
  };
};
