import { Ref } from 'vue';
import { AutoTabKey, JudicialAutomationModel } from '@/components/automation/judicial/types';
import {
  fieldsUniter,
  getCheckbox,
} from '@/components/automation/judicial/tabs/utils';

export const getAutoDebtorsActions = (
  model: Ref<JudicialAutomationModel>,
  localT: (s: string) => string,
) => {

  return fieldsUniter(AutoTabKey.debtorsActions, [
    getCheckbox('move_judicial_debtors_without_debt_to_archive', model, localT),
    getCheckbox('exclude_when_died', model, localT),
    getCheckbox('exclude_with_deregistration_date', model, localT),
    getCheckbox('include_children', model, localT),
    getCheckbox('allow_without_court', model, localT),
  ]);
};
