import { PeriodicTaskDescription } from '@/components/automation/judicial/types';

export const periodicTasks: PeriodicTaskDescription[] = [
  {
    taskName: 'judicial.auto_rosreestr_discharge',
    taskFlagField: 'auto_rosreestr_discharge',
    taskField: 'rosreestr_discharge_period',
  },
  {
    taskName: 'judicial.auto_rosreestr_discharge_force',
    taskFlagField: 'auto_rosreestr_discharge_period_force',
    taskField: 'rosreestr_discharge_period_force',
  },
  {
    taskName: 'judicial.auto_filing_complaint',
    taskFlagField: 'auto_filing_complaint',
    taskField: 'auto_filing_complaint_period',
  },
  {
    taskName: 'judicial.auto_fees_discharge',
    taskFlagField: 'auto_fees_discharge',
    taskField: 'fees_discharge_period',
  },
  {
    taskName: 'judicial.auto_filing_claim',
    taskFlagField: 'auto_filing_claim',
    taskField: 'filling_claim_period',
  },
  {
    taskName: 'judicial.auto_update_debtor_data',
    taskFlagField: 'auto_update_debtor_data',
    taskField: 'auto_update_debtor_data_period',
  },
  {
    taskName: 'judicial.auto_refiling_claims',
    taskFlagField: 'auto_refiling_claims',
    taskField: 'auto_refiling_claims_period',
  },
];
