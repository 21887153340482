import { CompanySettings, CompanySettingsSendType } from '@/hooks/useCompanies';
import { PeriodicTaskSchedule } from '@/hooks/useSchedule';
import { DebtorCompositeUpdateType } from '@/hooks/useDebtorCompositeUpdate';

export type KeysMatching<T, V> = {[K in keyof T]-?: T[K] extends V ? K : never}[keyof T]
export type BooleanField = KeysMatching<CompanySettingsJudicial, boolean>
export type ScheduleField = KeysMatching<CompanySettingsJudicial, PeriodicTaskSchedule | null>

export type PeriodicTaskDescription = {
  taskName: string;
  taskFlagField: BooleanField;
  taskField: ScheduleField;
}

export enum AutoTabKey { // automation tab key
  documents = 'documents',
  enrichment = 'enrichment',
  court = 'court',
  courtDecisions = 'courtDecisions',
  rosreestr = 'rosreestr',
  registry = 'registry',
  debtorsActions = 'debtorsActions',
  calculations = 'calculations',
}

type JudicialCommonPart = {
  judicial_automation_enabled: boolean;
  debtors_amount?: number;

  auto_attach_extracts_from_egrn: boolean;

  auto_refiling_claims: boolean;
  auto_refiling_claims_period: null | PeriodicTaskSchedule;
  auto_filing_isk: boolean;
  auto_filing_claim_by_no_debtor_info: boolean;
  auto_filing_claim_by_another_jurisdiction: boolean;

  sync_active_court_cases: boolean;
  sync_archive_court_cases: boolean;
  send_electronic_court_decisions: boolean;
  send_electronic_court_decisions_notify_emails: string[];

  auto_filing_complaint: boolean;
  auto_filing_complaint_period: null | PeriodicTaskSchedule;

  fees_discharge_period: null | PeriodicTaskSchedule;
  filling_claim_period: null | PeriodicTaskSchedule;

  rosreestr_discharge_period: null | PeriodicTaskSchedule;
  rosreestr_discharge_period_force: null | PeriodicTaskSchedule;

  move_judicial_debtors_without_debt_to_archive: boolean;

  exclude_when_died: boolean;
  exclude_with_deregistration_date: boolean;

  auto_update_debtor_data: boolean;
  auto_update_debtor_data_period: null | PeriodicTaskSchedule;

  auto_rosreestr_discharge_period_force: boolean;

  auto_correct_debt_period: boolean;

  auto_update_debtor_data_config: DebtorCompositeUpdateType[] | null;

  pp3_counter: number;

  include_children: boolean;
  allow_without_court: boolean;
  short_penalty_calculation: boolean;
  show_automatic_attachments: boolean;
}

export type CompanySettingsJudicial = JudicialCommonPart & CompanySettings;

export type JudicialAutomationModel = JudicialCommonPart & {

  amount_range: [number, number];
  debtors_amount: number;
  amount_setting: boolean;
  send_type: CompanySettingsSendType;
  need_rosreestr_discharge: boolean;
  auto_executive_transfer: boolean;
  rosreestr_characteristics: boolean;
  rosreestr_movement: boolean;
  unknown_owner_rosreestr_movement: boolean;
  debt_previous_rosreestr_movement: boolean;

  employees: number[];
  notify_emails: string[];
  auto_fees_discharge: boolean;
  auto_rosreestr_discharge: boolean;

  auto_filing_claim: boolean;

  court_test: boolean;
  court_user: number | null;
  without_fee: boolean;
  court_complaint_overdue: number | null;

  enrichment_by_address: boolean;
  enrichment_by_address_estates: boolean;
  judicial_auto_update_debtor_data_arguments?: boolean;

  short_penalty_calculation: boolean;
}
