import { computed, Ref } from 'vue';
import { getRegistryFields } from '@/components/automation/judicial/tabs/fields/registry';
import { getCourtDecisionsFields } from '@/components/automation/judicial/tabs/fields/courtDecisions';
import { getRosreestrFields } from '@/components/automation/judicial/tabs/fields/rosreestrEgrn';
import { getCourtFields } from '@/components/automation/judicial/tabs/fields/courts';
import { getEnrichmentFields } from '@/components/automation/judicial/tabs/fields/enrichment';
import { ActiveFormFieldType } from '@/hooks/useActiveForm';
import {
  JudicialActiveFormScheme, LabelValue,
} from '@/components/automation/judicial/tabs/types';
import { JudicialAutomationModel } from '@/components/automation/judicial/types';
import { useCommonFields } from '@/components/automation/judicial/tabs/fields/common';
import { useProtectedInject } from '@/hooks/useProtectedInject';
import { IsIonicApp } from '@/symbols';
import { useLocalI18n } from '@/hooks/useLocalI18n';
import { useI18n } from 'vue-i18n';
import { CompanyModuleType } from '@/pages/debtors/_module/useDebtorsActions';
import { useProtectedDefaultCompany } from '@/hooks/useProtectedDefaultCompany';
import { useFormEmployees } from '@/components/automation/useEmployees';
import {
  getAutoDebtorsActions,
} from '@/components/automation/judicial/tabs/fields/autoDebtorsActions';
import { getCalculationsFields } from '@/components/automation/judicial/tabs/fields/calculations';

export function useFields(
  model: Ref<JudicialAutomationModel>,
  emailOptions: Ref<LabelValue[]>,
) {

  const isIonicApp = useProtectedInject(IsIonicApp);
  const { t } = useLocalI18n('debtor.automatizing');
  const { t: rootT } = useI18n();
  const { companyModuleType } = useProtectedDefaultCompany();
  const { getCommonFields } = useCommonFields(model);
  const { onQueryEmployee, employees } = useFormEmployees();

  const hiddenFields = computed(() => {
    if (companyModuleType.value === CompanyModuleType.Vehicles) {
      return [
        'need_rosreestr_discharge',
        'auto_rosreestr_discharge',
        'auto_rosreestr_discharge_period_force',
        'rosreestr_discharge_period_force',
        'rosreestr_characteristics',
        'rosreestr_movement',
        'rosreestr_discharge_period',
        'rosreestr_label',
        'unknown_owner_rosreestr_movement',
      ];
    }
    return [];
  });

  return {
    fields: computed(() => ([

      ...getCommonFields(),

      ...getRegistryFields(
        model,
        rootT,
        onQueryEmployee,
        employees,
        emailOptions,
      ),
      ...getCourtDecisionsFields(model, rootT, employees),
      ...getCalculationsFields(model, rootT),
      ...getRosreestrFields(model),
      ...getCourtFields(model, t, employees),
      ...getEnrichmentFields(model, rootT),
      ...getAutoDebtorsActions(model, rootT),

      !isIonicApp && {
        key: 'court_label',
        field: 'court_label',
        type: 'label',
        style: { 'margin-bottom': '0 !important' },
        options: {},
      },
      !isIonicApp && {
        key: 'rosreestr_label',
        field: 'rosreestr_label',
        type: 'label',
        style: { 'margin-bottom': '0 !important' },
        options: {},
      },
      !isIonicApp && {
        key: 'registry_formation',
        type: 'label',
      },
    ] as JudicialActiveFormScheme)
      .filter(Boolean)
      .filter(({ key }) => !hiddenFields.value.includes(key))
      .map((field) => {
        if (field.type === 'label') {
          return {
            key: field.key,
            type: field.type,
            label: t(`label.${String(field.key)}`),
            style: field.style,
          };
        }

        return ({
          ...field,
          field: field.field || field.key,
          label: field.label === false ? false : t(`field.${String(field.key)}`),
          type: field.type || ActiveFormFieldType.input,
          options: {
            ...(field.options || {}),
            [
            field.type === ActiveFormFieldType.checkbox
              ? 'label'
              : 'placeholder'
            ]: field.options?.[
              field.type === ActiveFormFieldType.checkbox
                ? 'label'
                : 'placeholder'
            ] ?? t(`field.${String(field.key)}`),
            id: `modal_automation_judicial_${field.type}_${field.key}`,
          },
        });
      })),
  };
}
